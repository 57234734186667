<template>
  <div class="main">


    <!-- header end-->
    <!-- body start -->
    <div class="bodybox">

      <div class="textbox">
        <div class="row">
          <div class="index-toppic2">推荐载体</div>
          <div class="flex1">
            <span :class="index == -1 ? 'active1' : ''" @click="choese(null,-1)"
              >全部</span>
            <span :class="index == i ? 'active1' : ''"
                  v-for="(item, i) in CateList"
                  @click="choese(item,i)"
                    :key="i"
                    >{{ item.Name }}</span>
          </div>
          <div style="flotbox"
              v-for="(item,i) in BuildingList" :key="i"  @click="GetBuildingIn(item.SysID)">
            <div class="imgbox">
              <img v-if="item.FileList!=null &&item.FileList.length>0"
                         :src="item.FileList[0].URL"
                   mode="aspectFit"
                   alt="" />


                <span>{{item.Name}}</span>
            </div>
          </div>

        </div>
        <div class="row btnboxes">
            <div class="allbtn"
               @click="getmap">查看载体地图</div>
          <div class="allbtn"
               @click="getall">查看所有载体</div>
             
        </div>
        <div class="row">
          <div class="index-toppic2">载体设施</div>
          <div style="flotbox"
                v-for="(item,i) in BuildingSSList"  :key="i"  @click="GetBuildingIn(item.MainSysID)">
            <div class="imgbox"><img  
                         :src="item.URL"
                   alt="" /></div>
          </div>
         
        </div>
      </div>
    </div>
    <!-- body end -->

  </div>
</template>

<script>
// 公共样式
import timeFormat from "../util/time.js";
import '../assets/css/common.css'
export default {
  data() {
    return {
      index: -1,
      activeIndex: '3',
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      navlist: [
        {
          names: '首页',
          path: '/',
          id: '1',
        },
        {
          names: '政策资讯',
          path: '/news',
          id: '2',
        },
        {
          names: '产业载体',
          path: '/carrier',
          id: '3',
        },
        {
          names: '企业互动',
          path: '/interaction',
          id: '4',
        },
        {
          names: '金融服务',
          path: '/service',
          id: '5',
        },
        {
          names: '企业服务',
          path: '/enterpriseServices',
          id: '6',
        },
        {
          names: '招聘服务',
          path: '/recruit',
          id: '7',
        },
      ],
      CateList:[],
      Category:'',
      BuildingList:[],
      BuildingSSList:[],
    }
  },

  watch: {
    windowWidth(val) {
      let that = this
      console.log('实时屏幕宽度：', val)
      if (val > 990) {
        that.drawer = false
      }
    },
  },
  methods: {
    
    //   载体详情
    GetBuildingIn(id) {
      this.$router.push({ path: '/carrierDetails', query: { SysID: id } })
    },
    GetCateList() {
      let that = this
      that
        .$post('/api/Dictionary/List', {
          State: true,
          Type: 'LoadBuild',
          Sort: 'Sort ASC',
        })
        .then(function (data) {
          console.log(data)
          that.CateList = data.Obj
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 查看所有载体
    getall() {
      this.$router.push('/AllCarriers')
    },
      // 查看载体地图
    getmap() {
      this.$router.push('/map')
    },
    choese(row, e) {
      this.index = e
      if (row != null) {
        this.Category = row.SysID
      }else{
        this.Category = ""
      }

      this.PageIndex=1
      // this.BuildingList=[]
      // this.BuildingSSList=[]
      this.GetBuildingList()
      this.GetBuildingSSList()
    },
    handleSelect(e) {
      let that = this
      console.log(e)
      that.$router.push(e)
      setTimeout(() => {
        that.drawer = false
      }, 500)
    },
    getlogin() {
      this.$router.push('/login')
    },
    register() {
      this.$router.push('/register')
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    GetBuildingList() {
      let that = this
      that
        .$post('/api/Building/List', {
          State: true,
          Sort: 'CreateOn desc',
          Category:that.Category,
          PageSize: 3,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data)
          that.BuildingList = data.Obj
          
          that.BuildingList.forEach((v) => {
            
            if (v.FileList.length > 0) {
            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
              v.FileList[0].URL = that.urlOA + v.FileList[0].URL.substr(1)
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    GetBuildingSSList() {
      let that = this
      that
        .$post('/api/Building/SSList', {
          State: true,
          Sort: 'CreateOn desc',
          Category:that.Category,
          PageSize: 3,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data)
          that.BuildingSSList = data.Obj
          
          that.BuildingSSList.forEach((v) => {
              v.URL = that.urlOA + v.URL.substr(1)
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  mounted() {
    var that = this
    that.GetCateList()
    that.choese(null,-1)
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        window.fullWidth = document.documentElement.clientWidth
        that.windowHeight = window.fullHeight // 高
        that.windowWidth = window.fullWidth // 宽
      })()
    }
  },
}
</script>


<style scoped  lang="scss">
.btnboxes{
  display: flex;
  justify-content:center;
    align-items:center;

}
.allbtn {
  display: inline-block;
  height: 45px;
  line-height: 45px;
  padding: 0 30px;
 
  border: 1px solid #70bafd;
  margin-bottom: 30px;
  color: #70bafd;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 20px;
}
.imgbox {
  position: relative;
  float: left;
  width: 300px;
cursor: pointer;
  margin-right: 60px;
  margin-left: 40px;
  margin-bottom: 40px;

  height: 200px;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    height: 100%;

    width: 100%;
  }
  span{
    position: absolute;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: rgba(0,0,0,.4);
    bottom: 0;
    color: #fff;
    padding: 0 10px;
    box-sizing: border-box;
     text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  }
}
// banner
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.el-carousel__container {
  height: 1000px !important;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.textbox {
  width: 1200px;
  margin-left: 50%;
  transform: translateX(-50%);
  // padding-top: 100px;
  overflow: hidden;
}
.textleft {
  float: left;
  width: 900px;
}
.textright {
  float: left;
  width: 260px;
  // height: 600px;
  margin-left: 10px;
}
.item {
  position: relative;
  height: 160px;
  width: 100%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 160px;
    width: 280px;
  }
  .newsbox {
    position: absolute;
    left: 310px;
    height: 100%;
    width: 580px;
    padding: 6px;
    box-sizing: border-box;
    h3 {
      position: absolute;
      top: 0;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p {
      position: absolute;
      top: 60px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .itembottom {
      position: absolute;
      height: 40px;
      bottom: 0;
      line-height: 40px;
      font-size: 14px;
    }
  }
}
.items {
  font-size: 0.8rem;
  line-height: 36px;
  height: 36px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  span {
    line-height: 32px;
    height: 32px;
  }
}
.items:hover,
.item h3:hover,
.item p:hover {
  color: #2b60a5;
}

.aaa {
  display: none;
}
@media (max-width: 500px) {
  .flex1 span {
       margin-left: 0px;
    height: 30px;
    line-height: 30px;
    font-size: 10px;
    padding: 0 10px;
    cursor: pointer;
}

  .el-carousel__container {
    height: 150px !important;
  }
  .el-carousel__arrow {
    top: 25% !important;
  }
  .aaa {
    display: block;
  }

  .main .textbox {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
  }
  .textleft,
  .textright {
    float: left;
    width: 100%;
    overflow: hidden;
    h4 {
      text-align: center;
    }
  }
  .item {
    position: relative;
    height: 120px;
    width: 100%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      height: 120px;
      width: 30%;
    }
    .newsbox {
      position: absolute;
      left: 35%;
      height: 100%;
      width: 60%;
      padding: 6px;
      box-sizing: border-box;
      h3 {
        position: absolute;
        top: 0;
        font-size: 16px;
        height: 40px;
        line-height: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        position: absolute;
        height: 40px;
        line-height: 20px;
        top: 50px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .itembottom {
        position: absolute;
        height: 20px;
        bottom: 0;
        line-height: 20px;
        font-size: 12px;
      }
    }
  }
  .imgbox {
    position: relative;
    float: left;
    width: 90%;
    cursor: pointer;
    margin-right: 60px;
    margin-left: 5%;
    margin-bottom: 40px;
    height: 200px;
    overflow: hidden;
}
}
</style>